import React, { FC } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Layout, Menu } from "antd";
// import { HomeOutlined, StarOutlined, SettingOutlined, GlobalOutlined } from "@ant-design/icons";
import { IState } from "~stores/index";
import routes, { IRoutes } from "~modules/Routes";

import "./Sidebar.less";

const { Sider } = Layout;
const { SubMenu } = Menu;

const Sidebar: FC = () => {
  const ui = useSelector((state: IState) => state.ui);

  return (
    <Sider trigger={null} theme="dark" collapsible collapsed={ui?.sidebarCollapsed}>
      <div className="logo" />

      <Menu theme="dark" mode="inline" defaultSelectedKeys={[routes[0].key]}>
        {routes.map(function menuMapper(item: IRoutes): any {
          const { key, label, path, icon: Icon, children = [], showInMenu = true } = item;

          if (children.length > 0) {
            return (
              <SubMenu key={key} title={
                <span>
                  {Icon && (<Icon />)}
                  <span>{label}</span>
                </span>
              }>
                {children.map(menuMapper)}
              </SubMenu>
            )
          } else if (showInMenu) {
            return (
              <Menu.Item key={key} icon={Icon ? <Icon /> : undefined}>
                {label}
                {path && (<Link to={path} />)}
              </Menu.Item>
            );
          }

          return null;
        })}
        {/* <Menu.Item key="dashboard" icon={<HomeOutlined />}>
          Dashboard
          <Link to="/" />
        </Menu.Item>
        <Menu.Item key="following" icon={<StarOutlined />}>Following</Menu.Item>
        <SubMenu key="publish" title={
          <span>
            <GlobalOutlined />
            <span>Publish</span>
          </span>
        }>
          <Menu.Item key="offers">
            Offers
            <Link to="/offers" />
          </Menu.Item>
        </SubMenu>
        <SubMenu key="settings" title={
          <span>
            <SettingOutlined />
            <span>Settings</span>
          </span>
        }>
          <Menu.Item key="admin-right">
            Admin Right
            <Link to="/settings/admin-right" />
          </Menu.Item>
        </SubMenu> */}
      </Menu>
    </Sider>
  );
};

export default Sidebar;
