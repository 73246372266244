// Interface
export interface IUser {
  usertype?: string;
  verification?: object;
  status?: string;
  support_regions?: string[];
  region?: string;
  _id: string | null;
  model_version?: string;
  username?: string | null;
  email: string | null;
  display_name: string | null;
  activation_token?: string;
  activation_expires?: string;
  created_at?: string;
  updated_at?: string;
}

export interface ISession {
  initialized: boolean;
  success: boolean;
  access_token: string | null;
  refresh_token: string | null;
  expires_in: number;
  login_at: Date;
  expires_at: Date;
  remember_me: boolean;
  user: IUser;
}

// Action Types
export const SESSION_SET = "SESSION_SET";
export const SESSION_RESET = "SESSION_RESET";

export interface ISessionActionType {
  type: string;
  payload?: ISession;
  meta?: object | string;
}

// Initial State
export const initialState: ISession = {
  initialized: false,
  success: false,
  access_token: null,
  refresh_token: null,
  expires_in: 0,
  login_at: new Date(),
  expires_at: new Date(),
  remember_me: false,
  user: {
    _id: null,
    email: null,
    display_name: null,
  },
};

// Actions
export const sessionSet = (payload: ISession | undefined): ISessionActionType => ({
  type: SESSION_SET,
  payload,
});

export const sessionReset = (): ISessionActionType => ({
  type: SESSION_RESET,
});

// Reducer
function reducer(state = initialState, props: ISessionActionType): ISession {
  const { type, payload } = props;

  switch (type) {
    case SESSION_RESET:
      return initialState;
    case SESSION_SET:
      return {
        ...state,
        initialized: true,
        ...payload,
      };
    default:
      return state;
  }
};

export default reducer;
