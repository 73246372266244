// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { useState, useEffect, useCallback } from "react";
// import { useSelector } from "react-redux";
// import { IState } from "~stores/index";
import { IHttpProps, IHttpReturn } from "./types";
import { get } from "./base";

export default (props: IHttpProps): IHttpReturn => {
  const {
    url,
    initialOptions,
    autoReload = true,
    initialLoading = false,
    initialSearch = [],
    dataKey = "data",
    // authenticated = true
  } = props;
  const [loading, setLoading] = useState(initialLoading);
  // const [ready, setReady] = useState(false);
  const [error, setError] = useState(null);
  const [options, setOptions] = useState(initialOptions);
  const [search, setSearch] = useState(initialSearch);
  // const session = useSelector((state: IState) => state.session);
  const [response, setResponse] = useState({
    [dataKey]: [],
    current_page: 0,
    total_pages: 0,
    row_count: 0,
  });

  // Wrap fetch function in usecallback to make sure
  // it won't get called when the url and the options
  // are not updated
  const _get = useCallback(async () => {
    try {
      setLoading(true);
      setError(null);

      const req: any = await get(url, options);

      if (req) {
        setResponse(req.data);
      }
    } catch (errors) {
      if (errors.response) {
        setError(errors.response.data);
      } else {
        setError(errors);
      }
    } finally {
      setLoading(false);
    }
  }, [url, options]);

  // useEffect(() => {
  //   if (session?.access_token && session?.refresh_token) {
  //     setOptions((prevState) => {
  //       setReady(true);
  //       return {
  //         ...prevState,
  //         headers: {
  //           ...prevState?.headers,
  //           "X-Access-Token": session.access_token,
  //         },
  //       };
  //     });
  //   } else {
  //     setReady(true);
  //   }
  // }, [session]);

  // A custom search method
  useEffect(() => {
    const _search = search
      .slice()
      .map((item: any) => `${item.key}${item.op}${item.value}`);
    const searchQuery = _search.join(";");

    setOptions((prevState) => ({
      ...prevState,
      params: {
        ...prevState?.params,
        page: 0,
        search: searchQuery,
      },
    }));
  }, [search]);

  useEffect(() => {
    // Get data when options is changed
    // when the autoreload option is set to true
    if (autoReload) {
      _get();
    }
  }, [_get, autoReload]);

  return { loading, error, response, setOptions, search, setSearch, get: _get };
};
