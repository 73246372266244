const domain = process.env.REACT_APP_API_DOMAIN;
const secure = process.env.REACT_APP_API_SECURE;

export const imageHost = (path: string): string => {
  const proto: string = secure === 'true' ? 'https' : 'http';
  const url: string = `${proto}://${domain}${path}`;

  return url;
}

export default (version = 'v3'): string => {
  const proto: string = secure === 'true' ? 'https' : 'http';

  const url: string = `${proto}://${domain}/api/${version}/`;

  return url;
};
