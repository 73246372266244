import { useEffect, useRef } from "react";

interface IProps {
  callback: Function;
  delay: number;
}

export default (callback: Function, delay: number): void => {
  const savedCallback = useRef<Function>();

  // Remember the latest callback
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Setup interval
  useEffect(() => {
    function tick() {
      if (savedCallback.current) {
        savedCallback.current();
      }
    }

    if (delay !== null) {
      let id = setInterval(tick, delay);

      return () => clearInterval(id);
    }
  }, [delay]);
};
