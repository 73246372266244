import { IActionType } from "~common/types";

// Interface
export interface IUi {
  sidebarCollapsed: boolean;
  region: string;
  lang: string;
}

// Action Types
export const UI_SIDEBAR_TOGGLE = "UI_SIDEBAR_TOGGLE";
export const UI_SET_REGION = "UI_SET_REGION";
export const UI_SET_LANG = "UI_SET_LANG";

// Initial State
export const initialState: IUi = {
  sidebarCollapsed: false,
  region: "hk",
  lang: "en",
};

// Actions
export const uiSidebarToggle = (): IActionType => ({
  type: UI_SIDEBAR_TOGGLE,
});

export const setRegion = (payload: string): IActionType => ({
  type: UI_SET_REGION,
  payload,
});

export const setLang = (payload: string): IActionType => ({
  type: UI_SET_LANG,
  payload,
});

// Reducer
export default (state = initialState, action: IActionType): IUi => {
  const { type, payload } = action;

  switch (type) {
    case UI_SIDEBAR_TOGGLE:
      return {
        ...state,
        sidebarCollapsed: !state.sidebarCollapsed,
      };
    case UI_SET_REGION:
      return {
        ...state,
        region: payload,
      };
    case UI_SET_LANG:
      return {
        ...state,
        lang: payload,
      };
    default:
      return state;
  }
};
